import React from "react";
import axios from "axios";
import validator from "email-validator";

import "../button/buttons.scss";

axios.defaults.baseURL = process.env.API_URL;

export default class RequestDemoForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      registrationSent: false,
      sending: false,
      error: false,
      errorCode: 0,
      errorMessage: "",
      email: "",
      fullName: ""
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target.id;
    const value = event.target.value;

    const newState = {
      emailWarning: false,
      fullNameWarning: false,
    };
    newState[target] = value;

    this.setState(newState);
  };

  render() {
    if (this.state.sending) {
      return (
        <div>
          <h3 className="text-info">Request Processing</h3>
          <p>We are handling your request for a demo.</p>
        </div>
      );
    }

    if (this.state.demoRequested) {
      if (!this.state.error) {
        return (
          <div>
            <h3 className="text-success">Demo Requested, Thank you!</h3>
            <p>Your request has been processed and we will be in touch about your demo shortly.</p>
          </div>
        );
      } else {
        return (
          <div>
            <h3 className="text-warning">An Error Occurred</h3>
            <p>
              Please try again later.
            </p>
          </div>
        );
      }
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <input
          type="text"
          id="fullName"
          className="pa2 ba br2 w-100 mb3"
          value={this.state.fullName}
          onChange={this.handleInputChange}
          placeholder="Your Name"/>
        <input
          type="text"
          id="email"
          className={`pa2 ba br2 w-100 mb3 ${this.state.emailWarning && "warning"} `}
          value={this.state.email}
          onChange={this.handleInputChange}
          placeholder="Your Email"/>
        <input type="submit" className="w-100 button-primary tc"/>
      </form>
    );
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({emailWarning:false, sending: true});

    if (!validator.validate(this.state.email)) {
      this.setState({emailWarning: true, sending:false});
      return;
    }
    axios.post("/registrations/request-demo", this.state)
      .then(
        ({ data }) => {
          this.setState({
            demoRequested: true,
            sending: false
          });
        }
      )
      .catch(
        (error) => {
          this.setState({
            registrationSent: true,
            error: true,
            errorCode: !!error.response && error.response.status,
            errorMessage: error.message,
            sending: false
          });
        }
      );

    this.setState({ sending: true });
  };

}
