import React from   "react";
import Layout from "../components/layout";
import Landing from "../components/landing";
import Features from "../components/features";
import Testimonials from "../components/testimonials";
import Questions from "../components/questions";
import CTA from "../components/cta";
import PreFooter from "../components/prefooter";
import Container from "../components/container";

const IndexPage = () => (
  <Layout pageTitle="" hideLogo={true}>
    <main>
      <Landing/>
      <Features/>
      <Testimonials/>
      <Questions/>
      <Container extraClasses={"tc"} maxWidth={1200}>
        <CTA/>
      </Container>
      <PreFooter/>
    </main>
  </Layout>
);

export default IndexPage;
