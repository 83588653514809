import React, { Fragment } from "react";
import PrimaryButton from "../button";

const CTA = () => (
  <Fragment>
  <span className="f4 f3-ns tc mr2">
    It's time to change the way you test. Try <span className="sb">Video First</span> today.
  </span>
    <PrimaryButton text={"Sign up for free"} link={"https://app.videofirst.io/register"} extraClasses={"inline-flex mt3 ma2-ns f4 w-100 w-auto-ns"}/>
  </Fragment>
);

export default CTA;
