import React from "react";
import Icon from "../icon";
import IconPlay from "../icon/icons/play.icon";
import ImageOliver from "./oliver-lennon.jpg";
import Container from "../container";

const Landing = () => (
  <Container extraClasses={"vf-wallpaper-minimal bg-pos-50 cf"} maxWidth={1000}>
    <div className="fl w-100 w-25-ns pr3-ns pb3 pb0-ns">
      <img src={ImageOliver} className="w-25 w-100-m w-100-l br-100"/>
    </div>
    <div className="fl w-100 w-75-ns pl3-ns">
      <p className="f5 f3-ns lh-title mt0 mb3">"Quality is important to us, so we decided to use
        Video First from the start. It's a great collaborative tool that's helped to ensure the
        quality of our UI.</p>
      <p className="f5 f3-ns lh-title">The transition from a spreadsheet approach to a video
        based repository enabled us to save engineering time and increase quality."</p>
      <div className={"flex justify-between pt2"}>
        <span>Oliver Lennon - CEO, <a href="https://syndeo.cx/" className="no-underline"
                                      target="_blank">Syndeo.cx</a></span>
        {/*<a href="#" className={"flex items-center no-underline"}>*/}
          {/*<Icon icon={IconPlay} width={"1em"} height={"1em"} fill={"#0096f0"}/>*/}
          {/*<span className="pl2">Watch the interview with Oliver</span>*/}
        {/*</a>*/}
      </div>
    </div>
  </Container>
);

export default Landing;
