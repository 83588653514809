import React from "react";
import ImageLaptop from "./home-header-laptop.png";
import ImageApp from "./app-screenshot.png";
import Icon from "../icon";
import IconVF from "../icon/icons/vf-wordmark-rgb-no-spacing.icon";
import Container from "../container";
import LandingForm from "./LandingForm";
import './landing.scss';

const Landing = () => (
  <div className="vf-wallpaper-minimal">
    <div className="landing">
      <div className="image-container">
        <img src={ImageLaptop}/>
      </div>
      <div className="content-container mt5">
        <div className="w-100 w-70-ns mb3">
          <Icon icon={IconVF}/>
        </div>
        <h4 className="mb3 mt0 f5 f3-ns lh-title tc tl-ns normal">Collaborative user interface
          testing</h4>
        <p className="mb3 f4-ns lh-title tc tl-ns">
          Unify your tech team and improve quality from UI concept through
          to automated testing
        </p>
        <LandingForm/>
      </div>
    </div>
    <Container maxWidth={1200}>
      <div className="pa2 pa4-ns">
        <img src={ImageApp} className={"w-100"}/>
        <div className="w-100">
          <h3 className="pt3 pt5-ns mv0 f4 f3-ns tc lh-title normal mw8 mh-auto">
            Optimise your team's testing workflow with <span
            className={"sb"}>Video First App</span> -
            the collaborative tool
            that supports and improves your methodology.
          </h3>
        </div>
      </div>
    </Container>
  </div>
);

export default Landing;
