import React from "react";
import PrimaryButton from "../button/";
import Icon from "../icon";
import Webinar from "../icon/icons/webinar.icon";
import Container from "../container";
import ImageOffice from "../../../static/images/vf-office.jpg";
import RequestDemoForm from "./RequestDemoForm";

const PreFooter = () => (
    <Container extraClasses="vf-wallpaper-minimal" maxWidth={1200}>
      <div className="flex flex-wrap">
        <div className="w-100 w-50-ns pa3">
          <div className="mh3 h-100 bg-white flex flex-column justify-between">
            <Icon icon={Webinar} className={"mw-100"}/>
            <div className="pa4 pa5-l">
              <h4 className="f4 mt0 mb3 tc">Get in touch for a free demo</h4>
              <p className="f6 mt0 mb4 tc">
                Not sure where to start? Get in touch and we'll run through
                the product with you.
              </p>
              <RequestDemoForm/>
            </div>
          </div>
        </div>
        <div className="w-100 w-50-ns pa3">
          <div className="mh3 h-100 bg-white">
            <img src={ImageOffice} className="w-100"/>
            <div className="pa4 pa5-l">
              <h4 className="f4 mt0 mb3 tc">Video First Testing Workshops</h4>
              <p className="f6 mv0 tc pb4">Find out how to progress from manual to automated testing
                at one
                of our free tutorial sessions. View out Eventbrite page to get more details and
                register
                for our upcoming workshops.</p>
              <PrimaryButton link="/events/" text={"View Workshops"} extraClasses={"inline-flex w-100"}/>
            </div>
          </div>
        </div>
      </div>
    </Container>
);

export default PreFooter;
