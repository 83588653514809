import React from "react";
import { Link } from 'gatsby';

const Question = ({ question, answer, answerLink }) => (
  <div className="w-100 w-50-l pa3">
    <div className="h-100 bg-white pa4">
      <h4 className="f3 ma0 lh-title mb3 dark-gray">{question}</h4>
      <p className="f5 ma0 lh-title">{answer} <Link to={answerLink} className="link">Read more</Link></p>
    </div>
  </div>
);

export default Question;
