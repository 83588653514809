import React from "react";
import axios from "axios";
import validator from "email-validator";

import "../button/buttons.scss";

axios.defaults.baseURL = process.env.API_URL;

export default class LandingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      registrationSent: false,
      sending: false,
      error: false,
      errorCode: 0,
      errorMessage: "",
      email: ""
    };
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;

    this.emailInput.classList.remove("warning");

    this.setState({
      email: value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    if (!validator.validate(this.state.email)) {
      this.emailInput.focus();
      this.emailInput.classList.add("warning");
      return;
    }
    axios.post("/registrations", { "email": this.state.email })
      .then(
        ({ data }) => {
          this.setState({
            registrationSent: true,
            sending: false
          });
        }
      )
      .catch(
        (error) => {
          this.setState({
            registrationSent: true,
            error: true,
            errorCode: !!error.response && error.response.status, // 409 = user already exists
            errorMessage: error.message,
            sending: false
          });
        }
      );

    this.setState({ sending: true });
  };

  getErrorMessage = () => {
    if (this.state.errorMessage === "Network Error") {
      return "A network error occurred";
    }

    switch (this.state.errorCode) {
      case 409:
        return "An account already exists with this email address";
      default:
        return "An error occurred registering to Video First";
    }
  };

  render() {
    if (this.state.sending) {
      return (
        <div>
          <h3 className="text-info">Request Processing</h3>
          <p>Your request is now processing</p>
        </div>
      );
    }

    if (this.state.registrationSent) {
      if (!this.state.error) {
        return (
          <div>
            <h3 className="text-success">Account Created</h3>
            <p>Please check your email to complete registration and begin using Video First</p>
          </div>
        );
      } else {
        return (
          <div>
            <h3 className="text-warning">An Error Occurred</h3>
            <p>
              {this.getErrorMessage()}, please <a href="https://app.videofirst.io/register">try
              again</a>
            </p>
          </div>
        );
      }
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <input
          type="text"
          value={this.state.email}
          onChange={this.handleInputChange}
          name="email"
          className="pa3 ba br2 w-100 mb3"
          placeholder="Your Email"
          ref={(input) => {
            this.emailInput = input;
          }}
        />
        <div className="cf">
          <div className="fl w-100 w-50-l">
            <button type="submit"
                    className="w-100 button-primary">
              Start free 30 day trial
            </button>
          </div>
          <div className="fl w-100 w-50-l tc tl-l pa2">
            <span className="f6 f7-l b tracked ttu">Monthly from £39</span>
            <span> • </span>
            <span className="f6 f7-l b tracked ttu">No credit card required</span>
          </div>
        </div>
      </form>
    );
  }
}
