import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Question from "./Question";
import Container from "../container";

const Questions = () => (
  <StaticQuery
    query={graphql`
      query faqQuery {
        allDataJson {
          edges {
            node {
              faq {
                question,
                answer
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Container extraClasses={"office"} maxWidth={1200}>
        <h3 className="f3 tc pb5 white normal">Why test with <span
          className={"white sb"}>Video First App</span>?</h3>
        <div className="flex flex-wrap">
          {data.allDataJson.edges[0].node.faq.map((item, i) => (
            <Question
              key={i}
              question={item.question}
              answer={item.answer}
              answerLink={"/products/app"}
            />
          ))}
        </div>
      </Container>
    )}
  />
);

export default Questions;
