import React from "react";

export const FeaturesItem = ({ title, text, video, icon }) => (

  <div className={"w-100 flex flex-wrap items-center"}>
    <div className="w-100-s w-featurevideo-ns pa4 tc">
      {video}
    </div>
    <div className="w-100-s flex-1-ns pa3 tc tl-ns">
      <div className="flex justify-center justify-start-ns items-center mb3">
        {icon}
        <h3 className="pl2 ma0 f3 dib lh-title sb">
          {title}
        </h3>
      </div>
      <p className="f5 ma0 mb2 lh-title">{text}</p>
    </div>
  </div>
);

export default FeaturesItem;
