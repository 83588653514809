import React, { Fragment } from "react";
import Container from "../container";
import FeaturesItem from "./FeaturesItem";
import Icon from "../icon";
import Video from "../video";
import IconCapture from "../icon/icons/product-capture.icon";
import IconCollaborate from "../icon/icons/product-collaborate.icon";
import IconReview from "../icon/icons/product-review.icon";
import VideoCapture from "../video/videos/1-capture.mp4";
import VideoCollaborate from "../video/videos/2-collaborate.mp4";
import VideoReview from "../video/videos/3-review.mp4";
import WebmCapture from "../video/videos/1-capture.webm";
import WebmCollaborate from "../video/videos/2-collaborate.webm";
import WebmReview from "../video/videos/3-review.webm";
import PosterCapture from "../video/videos/1-capture.png";
import PosterCollaborate from "../video/videos/2-collaborate.png";
import PosterReview from "../video/videos/3-review.png";

export const Features = () => (
  <Container verticalPadding={true} extraClasses={"ph7-l pv4-ns tc tl-ns"} maxWidth={1200}>
    <FeaturesItem
      title={"Capture"}
      text={<Fragment>Native or browser - if your UI can be displayed on a screen then Video First
        can capture it.<br/><br/>Be it a manual or automated test, use Video First to host versions
        in a single repository and increase team visibility of UI progress.</Fragment>}
      icon={<Icon icon={IconCapture} width={35} height={35} fill={"#ff4110"}/>}
      video={<Video video={VideoCapture} webm={WebmCapture} poster={PosterCapture} className={"b--feature-red"}/>}
    />
    <FeaturesItem
      title={"Collaborate"}
      text={<Fragment>Play, approve and share videos with your team. Video First's rigorous review
        process documents progress and promotes reliability.</Fragment>}
      icon={<Icon icon={IconCollaborate} width={35} height={35}/>}
      video={<Video video={VideoCollaborate} poster={PosterCollaborate} webm={WebmCollaborate}
                    className={"b--feature-yellow"}/>}
    />
    <FeaturesItem
      title={"Review"}
      text={<Fragment>Share video reports from your library of UI test videos - why not create a
        weekly sprint summary overview?<br/><br/>As your UI progresses to completion, Video First
        supports the transition from expensive manual to efficient automated tests.</Fragment>}
      icon={<Icon icon={IconReview} width={35} height={35}/>}
      video={<Video video={VideoReview} poster={PosterReview} webm={WebmReview} className={"b--feature-green"}/>}
    />
  </Container>
);

export default Features;
